<template>
    <CoreUiForm
        v-slot="{ formData, isFormSubmitting }"
        :schema="schema"
        :on-submit="handleNewsletterFormSubmit"
        class="sim-newsletter-subscribe"
        notify-on-error="all"
    >
        <!--  TODO: create form element group  -->
        <BaseUiInput
            v-model:form="formData.email"
            class="!rounded-e-none !pl-5"
            color="white"
            :placeholder="$t('labels.your_email')"
            type="email"
            @input="sentSuccessfully = false"
        >
            <template #leading>
                <IconMail width="24" height="24" />
            </template>
        </BaseUiInput>

        <UiButton
            :color="!sentSuccessfully ? 'purple' : 'success'"
            size="ultra"
            type="submit"
            class="!rounded-s-none"
            :disabled="sentSuccessfully ? 'silently' : false"
            :loading="isFormSubmitting"
        >
            <template v-if="!sentSuccessfully">
                {{ $t('sections.newsletter.subscribe') }}
            </template>

            <IconCheckmark v-else height="21" color="white" />
        </UiButton>
    </CoreUiForm>
</template>

<script lang="ts" setup>

import { NewsletterType } from '@sim-api-enums/newsletter'
import { z } from 'zod'
import { EcoMailTag } from '../assets/ts/enums/eco-mail'

const { t } = useI18n()

const sentSuccessfully = ref<boolean>(false)
const ecoMail = useEcoMail()
const newslettersService = useNewslettersApiService()

async function handleNewsletterFormSubmit(payload: schema) {
    sentSuccessfully.value = false

    await newslettersService.post({
        email: payload.email,
        type: payload.type,
        gender_oriented_product_preference: null,
    })

    // purposely not awaiting
    ecoMail.subscribe(EcoMailTag.NEWSLETTER, payload.email)

    sentSuccessfully.value = true

    useNotifySuccess(t('_core_theme.notifications.newsletter_success'))
}

const schema = z.object({
    email: zStringMailType,
    type: z.number().default(NewsletterType.GENERAL),
    /* gender_oriented_product_preference: z.number().nullable().default(null), */ // TODO
})

export type schema = z.infer<typeof schema>

</script>

<style lang="scss" scoped>

.sim-newsletter-subscribe {
    display: flex;
    width: 100%;
}

:deep(.sim-newsletter-subscribe__input) {
    height: 100%;
    padding: 0 1.25rem;

    @include sim-text-small;
}
</style>
