<template>
    <CoreContainer class="sim-newsletter">
        <BaseContainerContent :padding="{ vertical: 'none' }">
            <BaseContainerContent
                :padding="{ horizontal: 'none', vertical: 'large' }"
                class="border-primary-600 flex flex-col items-center border-b border-solid"
            >
                <span class="sim-newsletter__heading">
                    {{ $t('sections.newsletter.heading') }}
                </span>

                <!--  SUBHEADING  -->
                <div class="sim-newsletter__subheading">
                    {{ $t('sections.newsletter.title') }}
                </div>

                <!--  INPUT  -->
                <div class="sim-newsletter__input-section z-10">
                    <NewsletterSubscribeComponent />
                </div>
            </BaseContainerContent>
        </BaseContainerContent>
    </CoreContainer>
</template>

<script lang="ts" setup>

</script>

<style lang="scss" scoped>

.sim-newsletter {
    background-color: $sim-c-primary-900;
    overflow-x: hidden;
}

.sim-newsletter__absolute-wrapper {
    position: relative;
    isolation: isolate;
}

.sim-newsletter__heading {
    font-size: 0.875rem;
    line-height: 1.125rem;
    text-align: center;
    flex-shrink: 0;

    color: $sim-c-white;

    @include sim-font-bold;
    @include sim-letters-widespread;
    text-transform: uppercase;
}

.sim-newsletter__subheading {
    margin-bottom: -1.2rem;
    font-size: 4rem;
    font-weight: bold;
    color: $sim-c-primary-400;
    opacity: 0.15;

    text-align: center;

    @include more-than(sm) {
        margin-bottom: -1.5rem;
        font-size: 6rem;
    }

    @include more-than(lg) {
        margin-bottom: -2.5rem;
        font-size: 8rem;
    }
}

.sim-newsletter__input-section {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 30rem;
}
</style>
